export interface NewRamsObj {
    title: string;
    type_name: string;
    type_id: number | null;
    orgunit_name: string;
    orgunit_id: number | null;
    status_name: string;
    status_id: number | null;
    source_name: string;
    source_id: number | null;
}